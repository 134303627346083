/**
 * @prettier
 */

export const TRUE_AS_STRING = '1'
export const FALSE_AS_STRING = '0'

export const ACCOUNT_SETTINGS_GA_CATEGORY = 'Account Settings'
export const ACCOUNT_TILES_GA_CATEGORY = 'Account Tiles'
export const APP_STORE_GA_CATEGORY = 'App store'
export const CAMPAIGN_INCENTIVE_GA_CATEGORY = 'Campaign Incentive'
export const CAMPAIGN_INDEX_GA_CATEGORY = 'Campaign Index'
export const CAMPAIGN_LAUNCH_GA_CATEGORY = 'Campaign Launch'
export const CAMPAIGN_MIGRATE_TOOL_GA_CATEGORY = 'Campaign Migrate Tool'
export const CAMPAIGN_NAVIGATION_GA_CATEGORY = 'Campaign navigation'
export const CAMPAIGN_PREVIEW_GA_CATEGORY = 'Campaign preview'
export const CAMPAIGN_RULES_GA_CATEGORY = 'Campaign Rules'
export const CAMPAIGN_SHOW_GA_CATEGORY = 'Campaign Show'
export const CREATE_NEW_CAMPAIGN_GA_CATEGORY = 'Create New Campaign'
export const CUSTOMER_SERVICE_GA_CATEGORY = 'Customer Service'
export const DASHBOARD_GA_CATEGORY = 'New Site Dashboard'
export const DEVICE_SWITCHER_GA_CATEGORY = 'Device Switcher'
export const EASY_EDITOR_GA_CATEGORY = 'Easy Editor'
export const EASY_EDITOR_OLD_GA_CATEGORY = 'Localizations'
export const FONT_UPLOADER_GA_CATEGORY = 'Font uploader'
export const FRAUD_SETTINGS_GA_CATEGORY = 'Fraud Settings'
export const FRESHDESK_GA_CATEGORY = 'Freshdesk'
export const GRID_EXPORT_GA_CATEGORY = 'Grid Export'
export const HEADER_GA_CATEGORY = 'Header site'
export const HTML_EDITOR_GA_CATEGORY = 'HTML Editor'
export const INTEGRATION_GA_CATEGORY = 'Integration'
export const LOYALTY_REDEMPTION_BATCHES_GA_CATEGORY = 'Loyalty Redemption Batches'
export const LOYALTY_ACTION_CONFIGS_GA_CATEGORY = 'Loyalty action configurations'
export const MULTIPLE_CAMPAIGN_EDITOR_MODAL_GA_CATEGORY = 'Multiple Campaign Editor Modal'
export const REPORTS_GA_CATEGORY = 'Reports'
export const SITE_SETTINGS_GA_CATEGORY = 'Site Settings'
export const STATIC_WIDGET_GA_CATEGORY = 'Static Widget'
export const TIER_BENEFITS_GA_CATEGORY = 'Tier benefits'
export const USER_SETTINGS_GA_CATEGORY = 'User settings'
export const WEBHOOKS_GA_CATEGORY = 'Webhooks'

export const TIME = {
  UNIT: 1,
  HOURS_IN_DAY: 24,
  MINUTES_IN_HOUR: 60,
  SECONDS_IN_MINUTE: 60,
  SECONDS_IN_HOUR: 60 * 60,
  SECONDS_IN_DAY: 24 * 60 * 60,
  SECONDS_IN_WEEK: 7 * 24 * 60 * 60,
}

export const KEY_CODES = {
  ENTER: 13,
  ESCAPE: 27,
  S: 83,
}

export const DASHBOARD_TILES_TYPES = [
  'DashboardAdvocacyRateTile',
  'DashboardAdvocateRewardsTile',
  'DashboardCampaignPerformanceTile',
  'DashboardCampaignsTile',
  'DashboardCustomizableRevenueTile',
  'DashboardFriendRewardsTile',
  'DashboardReferralsTile',
  'DashboardSiteRevenueTile',
  'DashboardTalkablePercentageOfRevenueTile',
  'DashboardTalkableRevenueTile',
  'DashboardAbstractMetricTile',
  'DashboardAllTimeMetricTile',
]

export const CAMPAIGN_APPEARANCES = {
  INLINE: 'inline',
  POPUP: 'popup',
  WIDGET: 'widget',
  GLEAM: 'gleam',
}

export const CAMPAIGN_PRESET_NAMES = {
  DASHBOARD: 'dashboard',
  GLEAM: 'gleam',
  INVITE: 'invite',
  EMAIL_CAPTURE: 'email_capture',
  LOYALTY: 'loyalty',
  CLAIM_BY_NAME: 'claim_by_name',
  LEADERBOARD: 'leaderboard',
}

export const CAMPAIGN_PLACEMENT_CATEGORY_ABBREVIATIONS = {
  STANDALONE: 'SA',
  POST_EVENT: 'PE',
  POST_PURCHASE: 'PP',
  REWARD_GLEAM: 'RG',
  FLOATING_WIDGET: 'FW',
  EMAIL_CAPTURE: 'EC',
  LOYALTY: 'LO',
  CLAIM_BY_NAME: 'CN',
}

export const CAMPAIGN_STATUSES_ORDER = ['LIVE', 'SCHEDULED', 'TEST', 'DISABLED']

export const CAMPAIGN_STATUSES_ORDER_WITH_KEYS = {
  Live: 0,
  Scheduled: 1,
  Test: 2,
  Disabled: 3,
}

export const CAMPAIGN_STATUSES = {
  LIVE: 'Live',
  SCHEDULED: 'Scheduled',
  TEST: 'Test',
  DISABLED: 'Disabled',
}

export const CAMPAIGNS_GROUPS = [
  {
    label: 'Live',
    value: 'live',
  },
  {
    label: 'Scheduled',
    value: 'scheduled',
  },
  {
    label: 'Test',
    value: 'test',
  },
  {
    label: 'Disabled',
    value: 'disabled',
  },
]

export const CAMPAIGNS_GROUPS_VALUES = CAMPAIGNS_GROUPS.map(group => group.value)

export const CAMPAIGNS_HUMAN_PRESET_NAMES = [
  {
    label: 'Advocate Dashboard',
    value: 'dashboard',
  },
  {
    label: 'Reward Gleam',
    value: 'gleam',
  },
  {
    label: 'Invite',
    value: 'invite',
  },
  {
    label: 'Invite Full Screen',
    value: 'invite_full_bleed',
  },
  {
    label: 'Leaderboard',
    value: 'leaderboard',
  },
  {
    label: 'Tiered Rewards',
    value: 'tiered',
  },
  {
    label: 'Claim by Name',
    value: 'claim_by_name',
  },
]

export const CAMPAIGNS_HUMAN_PLACEMENT_CATEGORIES = [
  {
    label: 'Post Purchase & Post Event',
    value: 'popup',
  },
  {
    label: 'Floating Widget',
    value: 'widget',
  },
  {
    label: 'Standalone',
    value: 'inline',
  },
  {
    label: 'Gleam',
    value: 'gleam',
  },
]
export const LOCALE_ENTRY_TRAITS = {
  ASSET: 'asset',
  COLOR: 'color',
  SHARE_IMAGE: 'share_image',
  STRING: 'string',
  BOOLEAN: 'boolean',
  MESSAGE: 'message',
}

export const HUMANIZED_ROLE_NAMES = {
  csp_only: 'CSP only',
  read: 'Read',
  write: 'Write',
  admin: 'Admin',
}

export const FILTER_GROUP_LIMIT = 5

export const CSP_PAGINATE_PER_PAGE = 25

export const CSP_MAX_PAGINATION_ITEMS = 15

export const CSP_REFERRALS_MAX_PAGINATION_ITEMS = 5

export const DASHBOARD_TILES_PAGINATE_PER_PAGE = 15

export const DASHBOARD_TILES_MAX_PAGINATION_ITEMS = 5

export const DASHBOARD_TILES_MIN_HUMANIZE_VALUE = 1_000_000

export const DASHBOARD_TILES_CAMPAIGNS_LIMIT = 100

export const INFINITY = '∞'

export const ANY_FRIEND_EVENT_CATEGORY = 'any'
export const OTHER_FRIEND_EVENT_CATEGORY = 'other'

export const CAMPAIGNS_DEFAULT_ITEMS_PER_PAGE = 10

export const CAMPAIGNS_MAX_PAGINATION_ITEMS = 5

export const THEME_STORE_SITE_CACHE_SLUG = 'talkable-theme-store'

export const COPY_MODAL_ID = 'campaign-copy-modal'

export const DROPZONE_TIMEOUT_REQUEST_CANCELED = 36_000_000

export const PLATFORM_EXTENSION_NAMES = {
  shopify: 'Shopify',
  demandware: 'Salesforce Commerce Cloud',
  magento: 'Magento',
}
export const PLATFORM_EXTENSION_ICON_NAMES = {
  shopify: 'shopify',
  demandware: 'salesforce',
  magento: 'magento',
}
export const TFA_MODAL_KEY = 'tfa_modal'

export const ORPHAN_COUNT_CAMPAIGNS = 5

export const COUNT_ADDITIONAL_VIEWS = 1 // Advocate social sharing

export const REPORTS_MIN_ITEMS_PER_PAGE = 10

export const REPORTS_DEFAULT_ITEMS_PER_PAGE = 25

export const REPORTS_MAX_ITEMS_PER_PAGE = 50

export const PLACEMENTS_ABBREVIATIONS = {
  EC: 'EC',
  FW: 'FW',
  LO: 'LO',
  CN: 'CN',
  PE: 'PE',
  PP: 'PP',
  RG: 'RG',
  SA: 'SA',
  LD: 'LD',
  LW: 'LW',
}

export const APPEARANCE_TO_PLACEMENT_TYPE = {
  inline: {
    referral: 'Standalone',
  },
  popup: {
    referral: 'Post Purchase',
    conversion: 'Email Capture',
  },
  widget: {
    referral: 'Floating Widget',
    loyalty: 'Loyalty',
  },
  gleam: {
    referral: 'Gleam',
  },
  event: {
    referral: 'Post Event',
  },
}

export const API_PROCESSOR_EMAIL = 'api@talkable.com'

export const ASC = 'ASC'
export const DESC = 'DESC'

export const LOYALTY_ACTION_CONFIG_TYPES = {
  event: 'Loyalty::EventActionConfig',
  referral: 'Loyalty::ReferralActionConfig',
  other: 'Loyalty::CustomActionConfig',
  optin: 'Loyalty::OptinActionConfig',
  recurring: 'Loyalty::RecurringActionConfig',
  promotion: 'Loyalty::PromotionActionConfig',
}

export const TIER_STEPS = {
  thresholdStep: 1,
  multiplierStep: 0.1,
}

export const JS_DRAG_CLASS_NAME = 'js-drag-handle'

export const GDPR_AND_CCPA_INCOMPATIBLE_VIEW_CATEGORIES = [
  'notifier_offers_share_via_email_reminder',
  'notifier_offers_share_via_email',
]

export const USER_ACCESS_ROLE_OPTIONS = [
  {
    value: 'no_access',
    label: 'No Access',
  },
  {
    value: 'csp_only',
    label: 'CSP only',
  },
  {
    value: 'read',
    label: 'Read',
  },
  {
    value: 'write',
    label: 'Write',
  },
  {
    value: 'admin',
    label: 'Admin',
  },
]

export const APP_STORE_EXTENSIONS_NAMES = {
  sheerid: 'sheerid',
}

export const OPERAND_CRITERIA = [
  {
    value: 'eq',
    label: 'Equal',
  },
  {
    value: 'not_eq',
    label: 'Not equal',
  },
  {
    value: 'regex',
    label: 'Regular expression (case insensitive)',
  },
]

export const DEFAULT_LANGUAGE = 'default'

export const ALL_LANGUAGES = [
  { value: 'default', label: 'Default Campaign Language' },
  { value: 'ab', label: 'Abkhazian' },
  { value: 'aa', label: 'Afar' },
  { value: 'af', label: 'Afrikaans' },
  { value: 'ak', label: 'Akan' },
  { value: 'sq', label: 'Albanian' },
  { value: 'am', label: 'Amharic' },
  { value: 'ar', label: 'Arabic' },
  { value: 'an', label: 'Aragonese' },
  { value: 'hy', label: 'Armenian' },
  { value: 'as', label: 'Assamese' },
  { value: 'av', label: 'Avaric' },
  { value: 'ae', label: 'Avestan' },
  { value: 'ay', label: 'Aymara' },
  { value: 'az', label: 'Azerbaijani' },
  { value: 'bm', label: 'Bambara' },
  { value: 'ba', label: 'Bashkir' },
  { value: 'eu', label: 'Basque' },
  { value: 'be', label: 'Belarusian' },
  { value: 'bn', label: 'Bengali (Bangla)' },
  { value: 'bh', label: 'Bihari' },
  { value: 'bi', label: 'Bislama' },
  { value: 'bs', label: 'Bosnian' },
  { value: 'br', label: 'Breton' },
  { value: 'bg', label: 'Bulgarian' },
  { value: 'my', label: 'Burmese' },
  { value: 'ca', label: 'Catalan' },
  { value: 'ch', label: 'Chamorro' },
  { value: 'ce', label: 'Chechen' },
  { value: 'ny', label: 'Chichewa, Chewa, Nyanja' },
  { value: 'zh', label: 'Chinese' },
  { value: 'zh-Hans', label: 'Chinese (Simplified)' },
  { value: 'zh-Hant', label: 'Chinese (Traditional)' },
  { value: 'cv', label: 'Chuvash' },
  { value: 'kw', label: 'Cornish' },
  { value: 'co', label: 'Corsican' },
  { value: 'cr', label: 'Cree' },
  { value: 'hr', label: 'Croatian' },
  { value: 'cs', label: 'Czech' },
  { value: 'da', label: 'Danish' },
  { value: 'dv', label: 'Divehi, Dhivehi, Maldivian' },
  { value: 'nl', label: 'Dutch' },
  { value: 'dz', label: 'Dzongkha' },
  { value: 'en', label: 'English' },
  { value: 'eo', label: 'Esperanto' },
  { value: 'et', label: 'Estonian' },
  { value: 'ee', label: 'Ewe' },
  { value: 'fo', label: 'Faroese' },
  { value: 'fj', label: 'Fijian' },
  { value: 'fi', label: 'Finnish' },
  { value: 'fr', label: 'French' },
  { value: 'ff', label: 'Fula, Fulah, Pulaar, Pular' },
  { value: 'gl', label: 'Galician' },
  { value: 'gd', label: 'Gaelic (Scottish)' },
  { value: 'gv', label: 'Gaelic (Manx)' },
  { value: 'ka', label: 'Georgian' },
  { value: 'de', label: 'German' },
  { value: 'el', label: 'Greek' },
  { value: 'kl', label: 'Greenlandic' },
  { value: 'gn', label: 'Guarani' },
  { value: 'gu', label: 'Gujarati' },
  { value: 'ht', label: 'Haitian Creole' },
  { value: 'ha', label: 'Hausa' },
  { value: 'he', label: 'Hebrew' },
  { value: 'hz', label: 'Herero' },
  { value: 'hi', label: 'Hindi' },
  { value: 'ho', label: 'Hiri Motu' },
  { value: 'hu', label: 'Hungarian' },
  { value: 'is', label: 'Icelandic' },
  { value: 'io', label: 'Ido' },
  { value: 'ig', label: 'Igbo' },
  { value: 'id, in', label: 'Indonesian' },
  { value: 'ia', label: 'Interlingua' },
  { value: 'ie', label: 'Interlingue' },
  { value: 'iu', label: 'Inuktitut' },
  { value: 'ik', label: 'Inupiak' },
  { value: 'ga', label: 'Irish' },
  { value: 'it', label: 'Italian' },
  { value: 'ja', label: 'Japanese' },
  { value: 'jv', label: 'Javanese' },
  { value: 'kl', label: 'Kalaallisut, Greenlandic' },
  { value: 'kn', label: 'Kannada' },
  { value: 'kr', label: 'Kanuri' },
  { value: 'ks', label: 'Kashmiri' },
  { value: 'kk', label: 'Kazakh' },
  { value: 'km', label: 'Khmer' },
  { value: 'ki', label: 'Kikuyu' },
  { value: 'rw', label: 'Kinyarwanda (Rwanda)' },
  { value: 'rn', label: 'Kirundi' },
  { value: 'ky', label: 'Kyrgyz' },
  { value: 'kv', label: 'Komi' },
  { value: 'kg', label: 'Kongo' },
  { value: 'ko', label: 'Korean' },
  { value: 'ku', label: 'Kurdish' },
  { value: 'kj', label: 'Kwanyama' },
  { value: 'lo', label: 'Lao' },
  { value: 'la', label: 'Latin' },
  { value: 'lv', label: 'Latvian (Lettish)' },
  { value: 'li', label: 'Limburgish ( Limburger)' },
  { value: 'ln', label: 'Lingala' },
  { value: 'lt', label: 'Lithuanian' },
  { value: 'lu', label: 'Luga-Katanga' },
  { value: 'lg', label: 'Luganda, Ganda' },
  { value: 'lb', label: 'Luxembourgish' },
  { value: 'gv', label: 'Manx' },
  { value: 'mk', label: 'Macedonian' },
  { value: 'mg', label: 'Malagasy' },
  { value: 'ms', label: 'Malay' },
  { value: 'ml', label: 'Malayalam' },
  { value: 'mt', label: 'Maltese' },
  { value: 'mi', label: 'Maori' },
  { value: 'mr', label: 'Marathi' },
  { value: 'mh', label: 'Marshallese' },
  { value: 'mo', label: 'Moldavian' },
  { value: 'mn', label: 'Mongolian' },
  { value: 'na', label: 'Nauru' },
  { value: 'nv', label: 'Navajo' },
  { value: 'ng', label: 'Ndonga' },
  { value: 'nd', label: 'Northern Ndebele' },
  { value: 'ne', label: 'Nepali' },
  { value: 'no', label: 'Norwegian' },
  { value: 'nb', label: 'Norwegian bokmål' },
  { value: 'nn', label: 'Norwegian nynorsk' },
  { value: 'ii', label: 'Nuosu' },
  { value: 'oc', label: 'Occitan' },
  { value: 'oj', label: 'Ojibwe' },
  { value: 'cu', label: 'Old Church Slavonic, Old Bulgarian' },
  { value: 'or', label: 'Oriya' },
  { value: 'om', label: 'Oromo (Afaan Oromo)' },
  { value: 'os', label: 'Ossetian' },
  { value: 'pi', label: 'Pāli' },
  { value: 'ps', label: 'Pashto, Pushto' },
  { value: 'fa', label: 'Persian (Farsi)' },
  { value: 'pl', label: 'Polish' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'pa', label: 'Punjabi (Eastern)' },
  { value: 'qu', label: 'Quechua' },
  { value: 'rm', label: 'Romansh' },
  { value: 'ro', label: 'Romanian' },
  { value: 'ru', label: 'Russian' },
  { value: 'se', label: 'Sami' },
  { value: 'sm', label: 'Samoan' },
  { value: 'sg', label: 'Sango' },
  { value: 'sa', label: 'Sanskrit' },
  { value: 'sr', label: 'Serbian' },
  { value: 'sh', label: 'Serbo-Croatian' },
  { value: 'st', label: 'Sesotho' },
  { value: 'tn', label: 'Setswana' },
  { value: 'sn', label: 'Shona' },
  { value: 'ii', label: 'Sichuan Yi' },
  { value: 'sd', label: 'Sindhi' },
  { value: 'si', label: 'Sinhalese' },
  { value: 'ss', label: 'Siswati' },
  { value: 'sk', label: 'Slovak' },
  { value: 'sl', label: 'Slovenian' },
  { value: 'so', label: 'Somali' },
  { value: 'nr', label: 'Southern Ndebele' },
  { value: 'es', label: 'Spanish' },
  { value: 'su', label: 'Sundanese' },
  { value: 'sw', label: 'Swahili (Kiswahili)' },
  { value: 'ss', label: 'Swati' },
  { value: 'sv', label: 'Swedish' },
  { value: 'tl', label: 'Tagalog' },
  { value: 'ty', label: 'Tahitian' },
  { value: 'tg', label: 'Tajik' },
  { value: 'ta', label: 'Tamil' },
  { value: 'tt', label: 'Tatar' },
  { value: 'te', label: 'Telugu' },
  { value: 'th', label: 'Thai' },
  { value: 'bo', label: 'Tibetan' },
  { value: 'ti', label: 'Tigrinya' },
  { value: 'to', label: 'Tonga' },
  { value: 'ts', label: 'Tsonga' },
  { value: 'tr', label: 'Turkish' },
  { value: 'tk', label: 'Turkmen' },
  { value: 'tw', label: 'Twi' },
  { value: 'ug', label: 'Uyghur' },
  { value: 'uk', label: 'Ukrainian' },
  { value: 'ur', label: 'Urdu' },
  { value: 'uz', label: 'Uzbek' },
  { value: 've', label: 'Venda' },
  { value: 'vi', label: 'Vietnamese' },
  { value: 'vo', label: 'Volapük' },
  { value: 'wa', label: 'Wallon' },
  { value: 'cy', label: 'Welsh' },
  { value: 'wo', label: 'Wolof' },
  { value: 'fy', label: 'Western Frisian' },
  { value: 'xh', label: 'Xhosa' },
  { value: 'yi, ji', label: 'Yiddish' },
  { value: 'yo', label: 'Yoruba' },
  { value: 'za', label: 'Zhuang, Chuang' },
  { value: 'zu', label: 'Zulu' },
]

export const EMAIL_VIEWS_IDENTIFIERS = [
  'friend_share_email',
  'friend_share_email_reminder',
  'advocate_reward_paid_email',
  'friend_reward_paid_email',
]

export const DISABLED_TRANSLATION_LOCALES = [
  {
    key: 'offer_terms',
    reason:
      'ChatGPT is not used to translate Terms & Conditions due to potential inaccuracies.' +
      'Legal documents like Terms & Conditions require precise language and absolute accuracy ' +
      'to ensure they are legally binding and clear in all jurisdictions. ' +
      'For this reason, we provide default translations in German, French, Polish, ' +
      'Spanish, and Dutch, which have been verified for accuracy and legal consistency.',
  },
  {
    key: 'talkable_terms_of_service',
    reason:
      'ChatGPT is not used to translate Terms & Conditions due to potential inaccuracies.' +
      'Legal documents like Terms & Conditions require precise language and absolute accuracy ' +
      'to ensure they are legally binding and clear in all jurisdictions. ' +
      'For this reason, we provide default translations in German, French, Polish, ' +
      'Spanish, and Dutch, which have been verified for accuracy and legal consistency.',
  },
]

export const LITE_FREE_LIMIT = 2000
